import React from 'react';
import * as Styled from './slpTopTitleStyles';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const SlpTopTitle = ({ sectionData }) => {
  return (
    <Styled.TitleContainer $background={sectionData?.image?.file?.url}>
      <Styled.LinkStyle
        href={
          isExternalUrlHref(sectionData?.assets[0]?.description)
            ? sectionData?.assets[0]?.description
            :addTrailingSlash( process.env.GATSBY_DOMAIN_URL +
              sectionData?.assets[0]?.description)
        }
        target={
          isExternalUrl(sectionData?.assets[0]?.description)
            ? '_blank'
            : '_self'
        }
      >
        <Styled.ImageStyle
          src={sectionData.assets[0]?.file?.url}
        ></Styled.ImageStyle>
      </Styled.LinkStyle>
      {sectionData?.textWithPaths && sectionData?.textWithPaths[0] && (
        <Styled.HeadingContainer>
          <Styled.Heading>{sectionData?.textWithPaths[0]?.text}</Styled.Heading>
        </Styled.HeadingContainer>
      )}
    </Styled.TitleContainer>
  );
};
export default SlpTopTitle;
